.container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 296px;
    padding: 8px;
    
    @media (min-width: 500px) {
        max-width: unset;
    }
}

.box {
    position: relative;
    height: 50px;
    width: 50px;
}

.box_front {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Helvetica;
    font-weight: 800;
    backface-visibility: hidden;
    border: solid 2px var(--black-color);

    background-color: var(--white-color);
}

.box_back {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Helvetica;
    font-weight: 800;
    backface-visibility: hidden;
    border: solid 2px var(--black-color);

    background-color: var(--black-color);
    color: var(--white-color);
}