html,
body {
    height: 100%;
}

* {
    cursor: none;
    -webkit-user-select: none;
}

body {
    --black-color: #1a1a1a;
    --white-color: #fafafa;
    --yellow-color: #fbf588;
    --blue-color: #00b1b2;
    --red-color: #c94245;
    --max-content-width: 632px;

    width: 100%;
    min-width: 320px;
    background-color: var(--white-color);
    margin: 0;
    font-family: PassionOneRegular -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin: 0;
}

div {
    display: flex;
}


@font-face {
    font-family: "PassionOneBold";
    src: local("PassionOneBold"),
    url("./fonts/PassionOne-Bold.ttf") format("truetype");
    font-weight: bold;
    font-stretch: 190%;
}

@font-face {
    font-family: "PassionOneRegular";
    src: local("PassionOneRegular"),
    url("./fonts/PassionOne-Regular.ttf") format("truetype");
}
