.container {
    height: 100vh;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container_black {
    background-color: var(--black-color);
    justify-content: space-around;
}

.info_text {
    max-width: var(--max-content-width);
    color: var(--black-color);
    font-family: 'PassionOneBold';
    font-size: 60px;
    line-height: 0.8;
}

.info_text_white {
    color: var(--white-color);
}

.contacts_container {
    flex-direction: column;
    align-items: center;

    @media (min-width: 500px) {
        flex-direction: row;
    }
}

.contacts_button {
    color: var(--white-color);
    font-family: 'PassionOneBold';
    font-size: 20px;
    font-weight: 800;
    text-transform: uppercase;
    padding: 8px;
}
