.cursor {
    background-color: var(--blue-color);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1000;
}
