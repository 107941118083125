.container {
    height: 100vh;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.scroll_down {
    height: 100px;
    width: 50px;
    -webkit-tap-highlight-color: transparent;
}