.container {
    flex-direction: column;
    background-color: var(--black-color);
    padding: 24px 16px;
    align-items: center;
}

.container_with_border {
    max-width: var(--max-content-width);
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px 0;
    border-top: 3px solid var(--white-color);
}

.buttons_container {
    margin: auto;
    gap: 10px;
}

.legal_container {
    grid-column-gap: 8px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.action_button {
    padding: 8px;
}

.legal_text {
    color: var(--white-color);
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
}